//CONTACT

export const contact = {
    name: 'Generational Pictures',
    phone: '+1 (917) 710-2682',
    phoneInt: '9177102682',
    email: 'felicitak@generational-pictures.com',
    address: '140 E. Ridgewood Avenue Suite 415\nSouth Tower Paramus, NJ',
    instagram: "https://www.instagram.com/generationalpictures/",
    linkedin: "https://www.linkedin.com/company/generational-pic/"
  }


// FORM URL

export const FORM_URL = 'https://nocodeform.io/f/652f1e5c4081185a963d2639'